import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useLayout } from '../../Layout/LayoutContext';
import { SupplierDetails } from '../../Types/base';
import { IProduct, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { catalogService, notificationsService, ordersService, profilesService } from '../../Utils/ApiService';
import { finalPrice, round } from '../../Utils/Utils';
import { DistributionOrder, InternalOrder } from '../../Types/order';
import CommonOrdering from './CommonOrdering';
import { reducers } from '../../Utils/Reducers';
import {
  createInternalOrder,
  generateExcelOrder,
  generateMMExcelOrder,
  getBestRabat,
} from '../../Utils/OrderGenerator';
import { useAuth0 } from '@auth0/auth0-react';
import { IProfile } from '@magistrmartin/eshop-frontend-shared';
import { saveAs } from 'file-saver';
import { getMMDistributionOrderedItemInfo } from './AoTableRow';

export default function AutomaticOrdering() {
  const [tab, setTab] = useState<number>(1);
  const [placeId, setPlaceId] = useState<number>(3);
  const [selectedSupplierId, setSelectedSupplierId] = useState<number>(0);
  const [supplierDetails, setSupplierDetails] = useState<SupplierDetails[]>([]);
  const [cdSupplierDetails, setCDSupplierDetails] = useState<SupplierDetails[]>([]);
  const [places, setPlaces] = useState<PickupPlace[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [AOCreateOrderModalOpened, setAOCreateOrderModalOpened] = useState(false);
  const [amountsToOrder, setAmountsToOrder] = useState<{
    [index: number]: number;
  }>({});
  const [aoOrderMail, setAOOrderMail] = useState('');
  const [aoDistributor, setAODistributor] = useState(1);
  const [aoPassword, setAOPassword] = useState('');

  const layout = useLayout();
  const { user } = useAuth0();

  useEffect(() => {
    ordersService.get(
      '/infrastructure/distributionPlaces',
      {},
      {
        success: setPlaces,
        error: () => layout.error('Při načítání lékáren došlo k chybě'),
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    catalogService.get(
      '/settings/allSuppliersDetails',
      {},
      {
        success: setSupplierDetails,
        error: console.log,
      }
    );
  }, []);

  useEffect(() => {
    catalogService.get(
      '/settings/centralDistributionSupplierDetails',
      {},
      {
        success: setCDSupplierDetails,
        error: console.log,
      }
    );
  }, []);

  useEffect(() => {
    loadProducts(tab, selectedSupplierId);
    // eslint-disable-next-line
  }, []);

  const changeSupplierSelection = (supplierId: number) => {
    setSelectedSupplierId(supplierId);
    loadProducts(tab, supplierId);
  };

  const changePlaceId = (newId: number) => {
    setPlaceId(newId);
    setAODistributor(places.filter((p) => p.id === newId)[0]?.distributors.filter((d) => d.isPrimary)[0]?.id || 0);
  };

  const changeTab = (tab: number) => {
    setTab(tab);
    if (tab === 0 && placeId === 0) changePlaceId(3);
    if (tab === 1 && placeId !== 0) changePlaceId(0);
    loadProducts(tab, selectedSupplierId);
  };

  const loadProducts = (tab: number, supplierId: number, notReset?: boolean) => {
    if (tab === 1 && supplierDetails.length === 0) return;
    layout.setIsLoading(true);
    const url =
      tab === 0
        ? '/products/centralDistributionProducts'
        : supplierId === -1
        ? '/products/all'
        : '/products/noauth/filter';
    if (notReset !== true) setProducts([]);
    catalogService.get(
      url,
      {
        filterType: tab === 0 ? undefined : 'supplier',
        filterValue: tab === 0 ? undefined : supplierDetails.filter((d) => d.id === supplierId)[0]?.supplierName || '',
        full: true,
        mapped: true,
        publishedOnly: false,
      },
      {
        success: (data: IProduct[]) => {
          setProducts(data?.filter((p) => p !== undefined && (!p.isObsolete || (p.stockAmmount || 0) > 0)) || []);
          layout.setIsLoading(false);
        },
        error: () => {
          layout.error('Při načítání produktů došlo k chybě');
          layout.setIsLoading(false);
        },
      }
    );
  };

  const createOrder = (
    orderedAmounts: { [index: number]: number },
    supplier?: SupplierDetails,
    predictions?:
      | {
          [index: string]: number;
        }
      | undefined
  ) => {
    layout.confirm('Opravdu chcete odeslat objednávku?', 'Objednávka bude závazně odeslána', () => {
      ordersService.post(
        '/distributionOrder',
        {},
        {
          products: products
            .filter(
              (p) =>
                (orderedAmounts[p.id] || 0) > 0 ||
                (predictions &&
                  Math.round(predictions[p.id]) -
                    (p.stockState?.filter((s) => s.placeId === placeId)[0]?.ammount || 0) >
                    (p.stockState?.filter((s) => s.placeId === 0)[0]?.ammount || 0))
            )
            .map((p) => {
              return {
                productId: p.id,
                price: getProductPrice(p),
                finalPrice: getProductPrice(p) * (orderedAmounts[p.id] || 0),
                productSubtitle: p.subtitle,
                productTitle: p.title,
                totalAmount: orderedAmounts[p.id] || 0,
                orderId: 0,
                unavailableAmount: predictions
                  ? Math.round(predictions[p.id]) -
                    Math.max(
                      (p.stockState?.filter((s) => s.placeId === placeId)[0]?.ammount || 0) +
                        (orderedAmounts[p.id] || 0),
                      0
                    )
                  : 0,
              };
            }),
          place: places.filter((plc) => plc.id === placeId)[0],
          placeId: placeId,
          id: 0,
          createdDate: new Date(),
        } as DistributionOrder,
        {
          success: () => {
            layout.success('Objednávka byla úspěšně odeslána');
            generateMMExcelOrder(
              products
                .filter((p) => (orderedAmounts[p.id] || 0) > 0)
                .map((p) =>
                  getMMDistributionOrderedItemInfo(
                    p,
                    places.filter((p) => p.id === placeId)[0],
                    getProductStockAmount,
                    places.filter((p) => p.countToDistribution),
                    predictions || {},
                    orderedAmounts
                  )
                ),
              getProductPrice,
              (blob) => saveAs(blob, 'Objednávka.xlsx')
            );
          },
          error: () => layout.error('Při odesílání objednávky došlo k chybě'),
        }
      );
    });
  };

  const downloadAOOrderExcel = () => {
    profilesService.get(
      '/',
      { userId: user?.sub },
      {
        success: (profile: IProfile) => {
          const formatedOrder = createInternalOrder(
            Object.keys(amountsToOrder)
              .filter((k) => amountsToOrder[parseInt(k)] > 0)
              .map((k) => ({
                product: products.filter((p) => p.id === parseInt(k))[0],
                amount: amountsToOrder[parseInt(k)],
              })),
            places.filter((p) => p.id === placeId)[0],
            profile,
            places.filter((p) => p.id === placeId)[0].distributors.filter((d) => d.id === aoDistributor)[0],
            selectedSupplierId
          );
          generateExcelOrder(formatedOrder, (blob) => saveAs(blob, 'Objednávka.xlsx'));
        },
        error: () => layout.error('Při načítání informací o přihlášeném uživateli došo k chybě.'),
      }
    );
  };

  const finishAOOrder = () => {
    profilesService.get(
      '/',
      { userId: user?.sub },
      {
        success: (profile: IProfile) => {
          const allPrds = Object.keys(amountsToOrder)
            .filter((k) => amountsToOrder[parseInt(k)] > 0)
            .map((k) => ({
              product: products.filter((p) => p.id === parseInt(k))[0],
              amount: amountsToOrder[parseInt(k)],
            }));
          const formatedOrder = createInternalOrder(
            allPrds,
            places.filter((p) => p.id === placeId)[0],
            profile,
            places.filter((p) => p.id === placeId)[0].distributors.filter((d) => d.id === aoDistributor)[0],
            selectedSupplierId
          );
          const usedRabatIds = allPrds
            .map(({ product, amount }) => getBestRabat(product, amount))
            .filter((r) => r !== undefined)
            .map((r) => r?.id);
          ordersService.post('/internal/orders/', {}, formatedOrder, {
            success: (order: InternalOrder) => {
              generateExcelOrder(order, (blob) => {
                const file = new File([blob], 'Objednávka.xlsx', {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  lastModified: new Date().getTime(),
                });
                notificationsService.uploadFile(
                  'emails/sendFromTemplateWithAttachmentFromMail',
                  file,
                  {
                    template: 'AOOrderTemplate',
                    to: aoOrderMail,
                    subject: 'Magistr Martin | Nová objednávka',
                    from: 'nakup@magistrmartin.cz',
                  },
                  {
                    secretToken: order.securityCode,
                    userName: `${profile.address?.name} ${profile.address?.surname}`,
                    salutation: '', // TODO
                  },
                  {
                    success: () => {},
                    error: () => layout.error('Při odesílání emailu objednávky došlo k chybě'),
                  }
                );
              });
              layout.success('Objednávka byla úspěšně uložena');
              setAOCreateOrderModalOpened(false);
            },
            error: () => layout.error('Při ukládání objednávky došlo k chybě'),
          });
          if (usedRabatIds.length > 0) {
            catalogService.post('/products/setLastSaledRabats', {}, usedRabatIds, {
              success: () => {},
              error: () => console.log('Error while updating rabats'),
            });
          }
          formatedOrder.orderedProductsList
            .filter((p) => p.extra > 0)
            .forEach((p) =>
              ordersService.post(
                '/stats/expectedFreeItemsDelivery',
                {},
                {
                  amount: p.extra,
                  id: 0,
                  isFromCatalog: true,
                  productFullName: `${p.productTitle} ${p.productSubtitle}`,
                  productId: p.productId,
                  supplier: supplierDetails.filter((sd) => sd.id === selectedSupplierId).map((sd) => sd.supplierTag)[0],
                },
                {
                  success: () => {},
                  error: () => console.log('Error while saving expected free items delivery', p),
                }
              )
            );
        },
        error: () => layout.error('Při načítání informací o přihlášeném uživateli došo k chybě.'),
      }
    );
  };

  const getProductPrice = (p: IProduct) =>
    (p !== undefined &&
      round(finalPrice(p) * places.filter((plc) => plc.id === placeId)[0].centralDistributionMargins, 2)) ||
    0;

  const getProductPriceWithoutMargins = (p: IProduct) => (p !== undefined && round(p.buyingPrice || 0, 2)) || 0;

  const getProductStockAmount = (productId: number, placeId: number) =>
    products.filter((p) => p.id === productId)[0].stockState?.filter((ss) => ss.placeId === placeId)[0]?.ammount || 0;

  const getProductStockAmountWithCentralStorage = (productId: number, placeId: number) =>
    placeId === 0
      ? places
          .filter((p) => p.countToDistribution)
          .map(
            (place) =>
              products.filter((p) => p.id === productId)[0].stockState?.filter((ss) => ss.placeId === place.id)[0]
                ?.ammount || 0
          )
          .reduce(reducers.sum, 0)
      : products.filter((p) => p.id === productId)[0].stockState?.filter((ss) => ss.placeId === placeId)[0]?.ammount ||
        0;

  const canBeOrdered = (product: IProduct) =>
    (product.stockState?.filter((s) => s.placeId === placeId).length || 0) > 0 &&
    product.stockState?.filter((s) => s.placeId === 0)[0]?.ammount !== undefined &&
    (product.buyingPrice || 0) > 0;

  const canBeOrderedAO = (product: IProduct) =>
    (placeId === 0 || (product.stockState?.filter((s) => s.placeId === placeId).length || 0) > 0) &&
    (product.buyingPrice || 0) > 0;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Tabs value={tab} onChange={(e, tab) => changeTab(tab)}>
            <Tab disabled label="Magistr Martin distribuce (pro lékárny)" />
            <Tab label="Centrální objednávání" />
          </Tabs>
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            value={{
              id: placeId || 0,
              label: places.find((p) => p.id === placeId)?.name || '',
            }}
            onChange={(e, val) => val?.id && changePlaceId(val.id)}
            options={places.filter((p) => p.id !== 0).map((p) => ({ id: p.id, label: p.name }))}
            fullWidth
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(p) => <TextField label="Lékárna" {...p} />}
          />
        </Grid>
      </Grid>
      <hr />
      <br />
      <br />
      <CommonOrdering
        createOrder={
          tab === 0
            ? createOrder
            : (amounts) => {
                setAmountsToOrder(amounts);
                setAOCreateOrderModalOpened(true);
                setAOOrderMail(
                  supplierDetails.filter((sd) => sd.id === selectedSupplierId)[0]?.representantEmail || ''
                );
              }
        }
        getProductPrice={tab === 0 ? getProductPrice : getProductPriceWithoutMargins}
        place={places.filter((p) => p.id === placeId)[0]}
        products={products}
        isCentralDistribution={tab === 0}
        getProductStockAmount={tab === 0 ? getProductStockAmount : getProductStockAmountWithCentralStorage}
        canBeOrdered={tab === 0 ? canBeOrdered : canBeOrderedAO}
        changeSupplierSelection={changeSupplierSelection}
        selectedSupplierId={selectedSupplierId}
        supplierDetails={supplierDetails}
        cdPlaces={places.filter((p) => p.countToDistribution)}
        changeProductCD={(pid, newCD) =>
          setProducts(products.map((p) => (p.id === pid ? { ...p, centralDistribution: newCD } : p)))
        }
        cdSupplierDetails={cdSupplierDetails}
        refreshProducts={() => loadProducts(tab, selectedSupplierId, true)}
      />
      <br />
      <Dialog open={AOCreateOrderModalOpened} fullWidth onClose={() => setAOCreateOrderModalOpened(false)}>
        <DialogTitle title="Odeslání objednávky" />
        <DialogContent>
          <Autocomplete
            value={{
              id: aoDistributor,
              label:
                places
                  .filter((p) => p.id === placeId)[0]
                  ?.distributors.filter((d) => d.id === aoDistributor)
                  .map((d) => `${d?.name} - ${d?.customerNumber}`)[0] || '',
            }}
            onChange={(e, val) => val?.id && setAODistributor(val.id)}
            options={places
              .filter((p) => p.id === placeId)[0]
              ?.distributors.map((d) => ({
                id: d?.id,
                label: `${d?.name} - ${d?.customerNumber}`,
              }))}
            fullWidth
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(p) => <TextField label="Distributor" {...p} />}
          />
          {places.filter((p) => p.id === placeId)[0]?.distributors.filter((d) => d.id === aoDistributor)[0]
            ?.password && (
            <TextField
              variant="standard"
              fullWidth
              label="Heslo"
              value={'*'.repeat(aoPassword.length)}
              onChange={(e) => setAOPassword(e.target.value)}
            />
          )}
          <Typography>Odeslat objednávku na email</Typography>
          <TextField
            variant="standard"
            fullWidth
            label="Email"
            value={aoOrderMail}
            onChange={(e) => setAOOrderMail(e.target.value)}
          />
          <Button color="primary" onClick={() => setAOOrderMail('nakup@magistrmartin.cz')}>
            Univerzální email
          </Button>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setAOCreateOrderModalOpened(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={downloadAOOrderExcel}>
            Stáhnout soubor s objednávkou
          </Button>
          <Button
            color="primary"
            disabled={
              aoOrderMail.length === 0 ||
              (aoPassword !==
                places.filter((p) => p.id === placeId)[0]?.distributors.filter((d) => d.id === aoDistributor)[0]
                  ?.password &&
                (places.filter((p) => p.id === placeId)[0]?.distributors.filter((d) => d.id === aoDistributor)[0]
                  ?.password?.length || 0) !== 0)
            }
            onClick={finishAOOrder}
          >
            Odeslat objednávku
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
