import { IBaseDiscount } from '@magistrmartin/eshop-frontend-shared';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';

export const openFileContext = (onSelect: (file: File[]) => void) => {
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('multiple', 'multiple');
  // @ts-ignore
  fileSelector.onchange = (e) => onSelect(e.currentTarget?.files);
  fileSelector.click();
};

export const getAllDiscounts = (prod: IProduct) => {
  const res: IBaseDiscount[] = [];
  if (prod.freeDelivery) res.push(prod.freeDelivery);
  if (prod.present) res.push(prod.present);
  if (prod.mixDiscount) res.push(prod.mixDiscount);
  if (prod.secondItemDiscount) res.push(prod.secondItemDiscount);
  if (prod.specialDiscount) res.push(prod.specialDiscount);
  if (prod.crossDiscount) res.push(prod.crossDiscount);
  return res;
};

export const getDiscountNameCZ = (dis: IBaseDiscount) => {
  return dis.type === 'FREE_DELIVERY'
    ? 'Doprava zdarma'
    : dis.type === 'PRESENT'
      ? 'Dárek zdarma'
      : dis.type === 'MIX'
        ? 'RABAT MIX'
        : dis.type === 'SECOND_ITEM'
          ? 'Sleva na druhý kus'
          : dis.type === 'SPECIAL'
            ? 'Speciální sleva'
            : dis.type === 'CROSS'
              ? 'Křížová sleva'
              : '';
};

export const getHeurekaPrice = (product: {
  reccomendedPrice?: number;
  reccomendedPricere?: number;
  totalPrice?: number;
  actionPrice?: number;
  heurekaPrice?: number;
  sellLimit?: number;
  customHeurekaPrice?: number;
  id: number;
  isRegulated?: boolean;
}) => {
  if (product.isRegulated) {
    return Math.min(
      round(infiniteIfZero(product.actionPrice), 0),
      round(infiniteIfZero(product.heurekaPrice), 0),
      round(infiniteIfZero(product.reccomendedPrice), 0),
      round(infiniteIfZero(product.customHeurekaPrice), 0)
    );
  } else {
    if ((product.customHeurekaPrice || 0) > 0) {
      return product.customHeurekaPrice || 0;
    } else {
      if ((product.heurekaPrice || 0) > 0 && (product.heurekaPrice || 0) >= (product.sellLimit || 0)) {
        return product.heurekaPrice === product.sellLimit ? product.heurekaPrice || 0 : (product.heurekaPrice || 0) - 1;
      } else {
        return (product.actionPrice || 0) === 0
          ? product.reccomendedPrice || product.reccomendedPricere || product.totalPrice || 0
          : product.actionPrice || 0;
      }
    }
  }
};

export const getZboziPrice = (product: {
  reccomendedPrice?: number;
  reccomendedPricere?: number;
  totalPrice?: number;
  actionPrice?: number;
  zboziPrice?: number;
  sellLimit?: number;
  customZboziPrice?: number;
  id: number;
  isRegulated?: boolean;
}) => {
  if (product.isRegulated) {
    return Math.min(
      round(infiniteIfZero(product.actionPrice), 0),
      round(infiniteIfZero(product.zboziPrice), 0),
      round(infiniteIfZero(product.reccomendedPrice), 0),
      round(infiniteIfZero(product.customZboziPrice), 0)
    );
  } else {
    if ((product.customZboziPrice || 0) > 0) {
      return product.customZboziPrice || 0;
    } else 
      if ((product.zboziPrice || 0) > 0 && (product.zboziPrice || 0) >= (product.sellLimit || 0)) {
        return product.zboziPrice === product.sellLimit ? product.zboziPrice || 0 : (product.zboziPrice || 0) - 1;
      } else {
        return (product.actionPrice || 0) === 0
          ? product.reccomendedPrice || product.reccomendedPricere || product.totalPrice || 0
          : product.actionPrice || 0;
      }
    }
};

const infiniteIfZero = (n?: number) => ((n || 0) === 0 ? 9999999999 : n || 0);

export const getReservationTypeCZ = (type: string) =>
  type === 'prescription' ? 'eRecept' : type === 'medicament' ? 'Volně prodejný produkt' : 'Veterinární přípravek';

export const removeProductDiscount = (product: IProduct, discount: IBaseDiscount) => {
  const res = { ...product };
  if (res.freeDelivery && res.freeDelivery.id === discount.id) res.freeDelivery = undefined;
  if (res.present && res.present.id === discount.id) res.present = undefined;
  if (res.mixDiscount && res.mixDiscount.id === discount.id) res.mixDiscount = undefined;
  if (res.secondItemDiscount && res.secondItemDiscount.id === discount.id) res.secondItemDiscount = undefined;
  if (res.specialDiscount && res.specialDiscount.id === discount.id) res.specialDiscount = undefined;
  if (res.crossDiscount && res.crossDiscount.id === discount.id) res.crossDiscount = undefined;
  return res;
};

export const addProductDiscount = (product: IProduct, discount: IBaseDiscount) => {
  const res = { ...product };
  if (discount.type === 'FREE_DELIVERY') res.freeDelivery = discount;
  if (discount.type === 'PRESENT') res.present = { ...discount, targetId: parseInt(discount.details || '') };
  if (discount.type === 'MIX') res.mixDiscount = { ...discount, free: parseInt(discount.details || '') };
  if (discount.type === 'SECOND_ITEM') res.secondItemDiscount = discount;
  if (discount.type === 'SPECIAL') res.specialDiscount = discount;
  if (discount.type === 'CROSS')
    res.crossDiscount = {
      ...discount,
      connectedDiscount: parseInt(discount.details || ''),
    };
  return res;
};

export const round = (x: number, dec: number) => Math.round(x * Math.pow(10, dec)) / Math.pow(10, dec);

export const separateThousands = (x: string | number | undefined) =>
  x === undefined ? '' : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const addDecimals = (x: number | undefined | string, decimals: number) => {
  if (x === undefined) return '';
  var res = x.toString();
  if (!res.includes('.') && decimals > 0) res += '.';
  const nullsToAdd = Math.max(0, decimals - (res.length - res.indexOf('.') - 1));
  return (
    res +
    Array.from(new Array(nullsToAdd))
      .map((_) => '0')
      .join('')
  );
};

export const sellingPrice = (product: IProduct) =>
  (product.actionPrice || 0) === 0
    ? product.reccomendedPrice || 0
    : Math.min(product.reccomendedPrice || 0, product.actionPrice || 0);

export const priceAfterTradeDiscount = (product: IProduct) =>
  Math.round((product.buyingPrice || 0) * (100 - (product.tradeDiscount || 0))) / 100;

export const rabatPercentage = (product: IProduct) => {
  if (product.buyingRabatBase !== undefined && product.buyingRabatFree !== undefined) {
    return Math.round((100 * product.buyingRabatFree) / (product.buyingRabatBase + product.buyingRabatFree || 1));
  } else if (product.rabats !== undefined && product.rabats.length > 0) {
    const rabat = product.rabats.find((p) => p.lastTimeUsedSale);
    if (rabat !== undefined) {
      return Math.round(
        (100 * (rabat.rabatFree + rabat.rabatExtra)) / (rabat.rabatFree + rabat.rabatExtra + rabat.rabatBase)
      );
    }
  }
  return 0.0;
};

export const priceAfterRabat = (product: IProduct) =>
  Math.round(priceAfterTradeDiscount(product) * (100 - rabatPercentage(product))) / 100;

export const totalBonification = (product: IProduct) =>
  product.doNotScann ? 0.0 : round(
    (((product.buyingPrice || 0) / (1 + (product.tradeSurchage || 0) / 100)) * (product.bonification || 0)) / 100,
    2
  );

export const priceAfterBonification = (product: IProduct) =>
  round(priceAfterInvoiceBonus(product) - totalBonification(product), 2);

export const totalInvoiceBonus = (product: IProduct) =>
  round(((product.invoiceBonus || 0.0) / 100.0) * (product.buyingPrice || 0.0), 2);

export const priceAfterInvoiceBonus = (product: IProduct) =>
  round(priceAfterRabat(product) - totalInvoiceBonus(product), 2);

export const getRelativeSellout = (product: IProduct) =>
  round((100 * (product.sellout || 0)) / ((product.buyingPrice || 0) / (1 + (product.tradeSurchage || 0) / 100)), 0);

export const finalPrice = (product: IProduct) => round(priceAfterBonification(product) - (product.sellout || 0), 2);

export const manufacturePrice = (product?: IProduct) =>
  (product?.buyingPrice || 0.0) /
  (1 + (product?.tradeSurchage || 0.0) / 100.0) /
  (1 + (product?.vatRate || 0.0) / 100.0);

export const getAbsoluteMargins = (product: IProduct) => round(sellingPrice(product) - finalPrice(product), 2);

export const getRelativeMargins = (product: IProduct) => getBasedRelativeMargins(product, sellingPrice(product));

export const getBasedRelativeMargins = (product: IProduct, basePrice: number) => {
  const res = round((100 * (basePrice - finalPrice(product))) / basePrice, 2);
  if (isNaN(res) || !isFinite(res)) return 0;
  return res;
};

export const dateToMYString = (date: Date) =>
  ('0' + (date.getMonth() + 1).toString()).slice(-2) + '/' + date.getFullYear().toString();

export const convertDateToDDMMYYYY = (date: String) =>
{
  return date.split("-").reverse().join("-");
}

export const removeDiacritics = (raw: string) => {
  let res = raw;
  const map: { [index: string]: string } = {
    ě: 'e',
    š: 's',
    č: 'c',
    ř: 'r',
    ž: 'z',
    ý: 'y',
    á: 'a',
    í: 'i',
    é: 'e',
    ů: 'u',
    ú: 'u',
    ď: 'd',
    ť: 't',
    ň: 'n',
  };
  Object.keys(map).forEach((k) => (res = res.replace(new RegExp(k, 'g'), map[k] as string)));
  return res;
};

export const toCzechTime = (date: Date | string) =>
  new Date(
    new Date(date).getFullYear(),
    new Date(date).getMonth(),
    new Date(date).getDate(),
    new Date(date).getHours(),
    new Date(date).getMinutes() - new Date(date).getTimezoneOffset(),
    new Date(date).getSeconds()
  );

export const getMarginsColor = (margins: number) => {
  if (margins < 16.67) return '#b20000';
  else if (margins < 20) return '#b65b00';
  else if (margins < 25) return '#baba00';
  else if (margins < 33.33) return '#5fbe00';
  else return '#00c200';
};

export const getHeurekaMarginsColor = (margins: number) => {
  if (margins < 8.0) return '#b20000';
  else if (margins < 15.0) return '#baba00';
  else if (margins < 23.0) return '#5fbe00';
  else return '#00c200';
};

export const copyToClipboard = (text: string) => {
  //@ts-ignore
  if (window.clipboardData && window.clipboardData.setData) {
    //@ts-ignore
    return window.clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    var textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy');
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};
export const nullOrUndefined = (x: any) => x === undefined || x === null;

export const getWeek = (param: Date) => {
  let onejan = new Date(param.getFullYear(), 0, 1);
  return Math.ceil(((param.getTime() - onejan.getTime()) / 86400000 + onejan.getDay()) / 7);
};
