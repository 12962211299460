import React from 'react';
import { Checkbox, IconButton, TableCell, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { IProduct, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { Cached, Edit, FileCopy, HelpOutline, Visibility, Warning } from '@mui/icons-material';
import { addDecimals, copyToClipboard, dateToMYString, round, separateThousands } from '../../Utils/Utils';
import { reducers } from '../../Utils/Reducers';
import { useLayout } from '../../Layout/LayoutContext';
import { catalogService } from '../../Utils/ApiService';
import { getBestRabat, IMMDistributionOrderedProductInfo } from '../../Utils/OrderGenerator';

interface IProps {
  product: IProduct;
  place: PickupPlace;
  getProductPrice: (product: IProduct) => number;
  isCentralDistribution: boolean;
  getProductStockAmount: (productId: number, placeId: number) => number;
  canBeOrdered: (product: IProduct) => boolean;
  cdPlaces: PickupPlace[];
  setHistorySelectedItem: (data: { product: IProduct; placeId: number }) => void;
  rawPredictions: { [index: string]: { [index: string]: number } } | undefined;
  predictions: { [index: string]: number } | undefined;
  orderedAmounts: { [index: number]: number };
  setOrderedAmounts: (ammounts: { [index: number]: number }) => void;
  selectedItems: { [index: number]: boolean };
  setSelecedItems: (newVal: { [index: number]: boolean }) => void;
  changeCD: (newCD: number) => void;
  lastYearTotalSales: { [index: number]: number };
}

const hasStockConnected = (product: IProduct, place: PickupPlace, placeId: number) =>
  place.id === 0 || (product?.stockState || []).filter((p) => p.placeId === placeId).length === 1;

export const getMMDistributionOrderedItemInfo = (
  product: IProduct,
  place: PickupPlace,
  getProductStockAmount: (productId: number, placeId: number) => number,
  cdPlaces: PickupPlace[],
  predictions: { [index: string]: number } | undefined,
  orderedAmounts: { [index: number]: number }
) =>
  ({
    product: { ...product },
    distributionStock:
      (product.stockState?.filter((ss) => ss.placeId === 0).length || 0) > 0
        ? (product.stockState?.filter((ss) => ss.placeId === 0)[0].ammount || 0).toString() + ' ks'
        : '-',
    guess: (predictions && `${Math.max(0, Math.round(predictions[product.id.toString()]))} ks`) || 'Načítám',
    onStock:
      place.id === 0
        ? `${
            getProductStockAmount(product.id, 0) -
            cdPlaces
              .filter((ppp) => (ppp.id || 0) > 0)
              .map((ppp) => getProductStockAmount(product.id, ppp.id || 0))
              .reduce(reducers.sum, 0)
          } - ${cdPlaces
            .filter((ppp) => (ppp.id || 0) > 0)
            .map((ppp) => getProductStockAmount(product.id, ppp.id || 0))
            .reduce(reducers.sum, 0)}`
        : hasStockConnected(product, place, place.id || 0)
        ? getProductStockAmount(product.id, place.id || 0)
        : '-',
    ordered: orderedAmounts[product.id] || 0,
  } as IMMDistributionOrderedProductInfo);

export default function AoTableRow({
  product,
  place,
  getProductPrice,
  isCentralDistribution,
  getProductStockAmount,
  canBeOrdered,
  cdPlaces,
  setHistorySelectedItem,
  rawPredictions,
  predictions,
  orderedAmounts,
  setOrderedAmounts,
  selectedItems,
  setSelecedItems,
  changeCD,
  lastYearTotalSales,
}: IProps) {
  const p = product;
  const layout = useLayout();

  const getErrorMessages = (product: IProduct) => {
    let res = [];
    if (place.id === 0) {
      res = cdPlaces
        .filter((p) => p.id !== 0 && (product.stockState?.filter((s) => s.placeId === p.id).length || 0) === 0)
        .map((p) => 'Produkt není spárován přes PDK se skladem lékárny - ' + p.name);
    } else {
      if ((product.stockState?.filter((s) => s.placeId === place.id).length || 0) === 0)
        res.push('Produkt není spárován přes PDK se skladem lékárny');
      if (product.stockState?.filter((s) => s.placeId === 0)[0]?.ammount === undefined)
        res.push('Produkt není spárován s MM Distribucí');
      if (!product.buyingPrice) res.push('Nákupní cena produktu není dostupná');
    }
    return res;
  };

  const mmDistributionInfo = getMMDistributionOrderedItemInfo(
    product,
    place,
    getProductStockAmount,
    cdPlaces,
    predictions,
    orderedAmounts
  );
  const updateOrderedAmount = (value: number) =>
    setOrderedAmounts({
      ...orderedAmounts,
      // [p.id]: Math.min(Math.max(0, value), place.id === 0 ? 99999999 : getProductStockAmount(p.id, 0)),
      [p.id]: Math.max(0, value),
    });

  return (
    <TableRow key={p.id}>
      <TableCell style={{ minWidth: 90 }}>
        {canBeOrdered(p) ? (
          <Checkbox
            checked={selectedItems[p.id]}
            onClick={() =>
              setSelecedItems({
                ...selectedItems,
                [p.id]: !selectedItems[p.id],
              })
            }
            color="primary"
          />
        ) : (
          <Tooltip title={<div style={{ whiteSpace: 'pre-line', fontSize: 14 }}>{getErrorMessages(p).join('\n')}</div>}>
            <IconButton size="large">
              <Warning color="error" />
            </IconButton>
          </Tooltip>
        )}
        {place.id === 0 && getErrorMessages(p).length > 0 && (
          <Tooltip title={<div style={{ whiteSpace: 'pre-line', fontSize: 14 }}>{getErrorMessages(p).join('\n')}</div>}>
            <IconButton size="large">
              <Warning color="error" />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell
        style={{
          cursor: 'pointer',
        }}
        onClick={() => setHistorySelectedItem({ product: p, placeId: place.id || 0 })}
      >
        {p.id}
      </TableCell>
      <TableCell
        style={{
          cursor: 'pointer',
        }}
        onClick={() => setHistorySelectedItem({ product: p, placeId: place.id || 0 })}
      >
        <FileCopy
          onClick={(e) => {
            copyToClipboard(p.title + ' ' + p.subtitle);
            layout.info('Text byl zkopírován to clipboardu.');
            e.stopPropagation();
          }}
        />
        &nbsp;
        {p.title} {p.subtitle}
        <br />
        <Typography variant="caption" color="textSecondary">
          {p.supplierTag} - {Object.keys(lastYearTotalSales).length}
        </Typography>
      </TableCell>
      {!isCentralDistribution && (
        <TableCell
          style={
            new Date(p.expiration?.toString() || new Date().toString()).getTime() - new Date().getTime() <= 15778800000
              ? {
                  color: 'red',
                }
              : new Date(p.expiration?.toString() || new Date().toString()).getTime() - new Date().getTime() <=
                31557600000
              ? { color: 'orange' }
              : new Date(p.expiration?.toString() || new Date().toString()).getTime() - new Date().getTime() <=
                65745000000
              ? {}
              : { color: 'green', fontWeight: 'bolder' }
          }
        >
          {p.expiration &&
          (p.expiration?.toString() || '').length > 0 &&
          new Date(p.expiration.toString()).getFullYear() > 2020 ? (
            dateToMYString(new Date(p.expiration.toString()))
          ) : (
            <>
              - {p.lastKnownExpiration && `(${dateToMYString(new Date(p.lastKnownExpiration))})`}
              <br />
              <Tooltip title="Přidat produkt na watchlist">
                <Visibility
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    catalogService.post(
                      '/pharmos/watchList',
                      {},
                      {
                        id: 0,
                        productId: p.id,
                        productName: `${p.title} ${p.subtitle}`,
                        foundDate: undefined,
                      },
                      {
                        success: () => layout.success('Produkt byl úspěšně přidán na Watch List'),
                        error: () => layout.error('Při přidávání produktu došlo k chybě'),
                      }
                    )
                  }
                />
              </Tooltip>
            </>
          )}
        </TableCell>
      )}
      <Tooltip
        title={
          place.id === 0 ? (
            <div style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
              {cdPlaces
                .filter((pp) => pp.id !== 0)
                .map(
                  (pp) =>
                    `${pp.name}: ${
                      hasStockConnected(p, place, pp.id || 0) ? getProductStockAmount(p.id, pp.id || 0) : '-'
                    }`
                )
                .join('\n')}
            </div>
          ) : (
            ''
          )
        }
      >
        <TableCell style={{ textAlign: 'center' }}>
          <>
            {mmDistributionInfo.onStock}{' '}
            <span style={{ color: 'grey' }}>
              (
              {place.id === 0
                ? Object.values(lastYearTotalSales).reduce(reducers.sum, 0)
                : (place.id || -1) in lastYearTotalSales
                ? lastYearTotalSales[place.id || 0]
                : '-'}
              )
            </span>
          </>
        </TableCell>
      </Tooltip>
      <Tooltip
        title={
          rawPredictions && place.id === 0 ? (
            <div style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
              {cdPlaces
                .filter((pp) => pp.id && pp.id > 0)
                .map((pp) => `${pp.name}: ${Math.max(0, Math.round(rawPredictions[pp.id || 0][p.id.toString()]))}`)
                .join('\n')}
            </div>
          ) : (
            ''
          )
        }
      >
        <TableCell style={{ textAlign: 'center' }}>
          {mmDistributionInfo.guess}
          <br />
          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() =>
              p.groupPackageAmmount &&
              updateOrderedAmount(Math.ceil(mmDistributionInfo.ordered / p.groupPackageAmmount) * p.groupPackageAmmount)
            }
            variant="caption"
            color="textSecondary"
          >
            Počet folie: {p.groupPackageAmmount}
          </Typography>
        </TableCell>
      </Tooltip>
      {isCentralDistribution && (
        <TableCell style={{ textAlign: 'center' }}>{mmDistributionInfo.distributionStock}</TableCell>
      )}
      <TableCell style={{ textAlign: 'center' }}>
        <TextField
          variant="standard"
          style={{ width: 52 }}
          disabled={!canBeOrdered(p)}
          value={mmDistributionInfo.ordered}
          onChange={(e) => updateOrderedAmount(parseInt(e.target.value))}
          type="number"
        />
        {place.id === 0 && (
          <Tooltip
            title={
              (rawPredictions && (
                <div style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
                  {[
                    `Centrální distribuce: ${
                      getProductStockAmount(p.id, 0) -
                      cdPlaces
                        .filter((ppp) => (ppp.id || 0) > 0)
                        .map((ppp) => getProductStockAmount(p.id, ppp.id || 0))
                        .reduce(reducers.sum, 0)
                    }`,
                    '',
                    ...cdPlaces
                      .filter((pp) => pp.id && pp.id > 0)
                      .map(
                        (pp) =>
                          `${pp.name}: ${Math.max(
                            0,
                            Math.max(0, Math.round(rawPredictions[pp.id || 0][p.id.toString()])) -
                              getProductStockAmount(p.id, pp.id || 0)
                          )}`
                      ),
                  ].join('\n')}
                </div>
              )) ||
              ''
            }
          >
            <HelpOutline />
          </Tooltip>
        )}
        <br />
        <Typography
          style={{ cursor: 'pointer' }}
          onClick={() =>
            p.higherGroupPackageAmmount &&
            updateOrderedAmount(
              Math.ceil(mmDistributionInfo.ordered / p.higherGroupPackageAmmount) * p.higherGroupPackageAmmount
            )
          }
          variant="caption"
          color="textSecondary"
        >
          Počet karton: {p.higherGroupPackageAmmount}
        </Typography>
      </TableCell>
      {!isCentralDistribution && (
        <TableCell>
          <Tooltip title="Změnit CD">
            <IconButton
              onClick={() => {
                catalogService.post(
                  'products/changeCentralDistribution',
                  {
                    id: p.id,
                    isDistributed: ((p.centralDistribution || 0) + 1) % 3,
                  },
                  null,
                  {
                    success: () => changeCD(((p.centralDistribution || 0) + 1) % 3),
                    error: () => layout.error('Při změně došlo k chybě'),
                  }
                );
              }}
              size="large"
            >
              <Cached />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
      <Tooltip
        title={
          isCentralDistribution ? (
            ''
          ) : (
            <div style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
              {[
                `Sleva: ${p.tradeDiscount || 0}`,
                `Bonifikace: ${p.bonification || 0}`,
                `Bonus faktura: ${round(p.invoiceBonus || 0, 2)}`,
                `Sell-out: ${p.sellout || 0}`,
                `Rabat: ${
                  [getBestRabat(p, mmDistributionInfo.ordered)].map(
                    (r) =>
                      (r &&
                        `${r.rabatBase} + ${r.rabatFree} + ${r.rabatExtra} (${round(
                          (100 * (r.rabatExtra + r.rabatFree)) / (r.rabatBase + r.rabatExtra + r.rabatFree),
                          2
                        )} %)`) ||
                      '-'
                  )[0]
                }`,
                p.rabats?.length
                  ? `(${p.rabats?.map(
                      (r) =>
                        `${r.rabatBase} + ${r.rabatFree} + ${r.rabatExtra} (${round(
                          (100 * (r.rabatExtra + r.rabatFree)) / (r.rabatBase + r.rabatExtra + r.rabatFree),
                          2
                        )} %)`
                    )})`
                  : '',
                `Osobní poznámka: ${p.personalRemark}`,
              ].join('\n')}
            </div>
          )
        }
      >
        <TableCell style={{ textAlign: 'center' }}>
          {addDecimals(separateThousands(getProductPrice(p)), 2) || '-'} Kč <br />
          <Tooltip title="Otevřít nastavení produktu">
            <Edit
              style={{ cursor: 'pointer' }}
              onClick={() => {
                localStorage.setItem('productsSearch', p.id.toString());
                localStorage.removeItem('productsFilters');
                localStorage.removeItem('productsPage');
                window.open(`/products`, '_blank');
              }}
            />
          </Tooltip>
        </TableCell>
      </Tooltip>
      <TableCell style={{ textAlign: 'center' }}>
        {addDecimals(separateThousands(round((getProductPrice(p) || 0) * (orderedAmounts[p.id] || 0), 2)), 2)} Kč
      </TableCell>
      {isCentralDistribution && (
        <TableCell style={{ textAlign: 'center' }}>
          {getProductPrice(p) < (p.buyingPrice || 0) && (
            <>
              {addDecimals(separateThousands(round((p.buyingPrice || 0) - getProductPrice(p), 2)), 2)}
              &nbsp;Kč
              <br />
              {round((100 * ((p.buyingPrice || 0) - getProductPrice(p))) / (p.buyingPrice || 1), 2)}
              &nbsp;%
            </>
          )}
        </TableCell>
      )}
    </TableRow>
  );
}
