import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, Tab, Tabs, TextField, Theme, Typography } from '@mui/material';
import LoadingFab from '../Components/LoadingFab';
import { imagesService, webStructureService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import { openFileContext } from '../Utils/Utils';
import { ShippingPaymentConfiguration } from '@magistrmartin/eshop-frontend-shared';
import { createStyles, makeStyles } from '@mui/styles';
import { domains } from '../Utils/Mutations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `calc(100% - ${theme.spacing(3)})`,
    },
    saveBtn: {
      position: 'fixed',
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      zIndex: 999,
    },
  })
);

const shippingTypes = [
  { label: 'Osobní převzetí', column: 'personalPickup' },
  { label: 'Zásilkovna: výdejní místo', column: 'packeta' },
  { label: 'Zásilkovna: doručení domů', column: 'packetaHome' },
];

const paymentTypes = [
  { label: 'Bankovním převodem', column: 'bankTransaction' },
  { label: "Klasický bankovní převod", column: 'bankTransactionEmail' },
  { label: 'Platební bránou', column: 'paymentGate' },
  { label: 'Při převzetí', column: 'pickup' },
];

interface IPricesConfigCardProps {
  types: { label: string; column: string }[];
  label: string;
  category: string;
  config: ShippingPaymentConfiguration;
  layout: any;
  changeConfig: (card: string, category: string, field: string, value: string) => void;
}

function PricesConfigCard({ types, category, changeConfig, config, label, layout }: IPricesConfigCardProps) {
  const getSaveFile = (column: string) => (files?: File[]) => {
    if (files !== undefined && files.length > 0) {
      if (files[0].size >= 1048576) {
        new Compressor(files[0], {
          quality: 0.8,
          convertSize: 1048576,
          success: (result) => {
            layout.warning('Obrázek byl automaticky komprimován, může dojít ke ztrátě kvality.');
            getSaveFile(column)([new File([result], files[0].name + '.jpg')]);
          },
          error: (err) => layout.error('Při kompresi došlo k chybě: ' + err.message),
        });
      } else {
        imagesService.uploadFile('uploadImage', files[0], { location: 'images' }, null, {
          success: (file) =>
            changeConfig(
              category,
              column,
              'image',
              `https://api.magistrmartin.cz/images/noauth/image/images/${JSON.parse(file).location}`
            ),
          error: layout.error,
        });
      }
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{label}</Typography>
        <br />
        {
          //@ts-ignore
          types.map((del) => (
            <React.Fragment key={del.column}>
              <Typography variant="caption">{del.label}</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={2}>
                  <TextField
                    variant="standard"
                    // @ts-ignore
                    value={config[category][del.column].price}
                    onChange={(e) => changeConfig(category, del.column, 'price', e.target.value)}
                    label="Cena"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <TextField
                    variant="standard"
                    // @ts-ignore
                    value={config[category][del.column].freeFrom}
                    onChange={(e) => changeConfig(category, del.column, 'freeFrom', e.target.value)}
                    label="Zdarma od"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <TextField
                    variant="standard"
                    // @ts-ignore
                    value={config[category][del.column].tax}
                    onChange={(e) => changeConfig(category, del.column, 'tax', e.target.value)}
                    label="DPH"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <TextField
                    variant="standard"
                    // @ts-ignore
                    value={config[category][del.column].pdk}
                    onChange={(e) => changeConfig(category, del.column, 'pdk', e.target.value)}
                    label="PDK"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    variant="standard"
                    // @ts-ignore
                    value={config[category][del.column].label}
                    onChange={(e) => changeConfig(category, del.column, 'label', e.target.value)}
                    label="Popisek v eshopu"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <TextField
                    variant="standard"
                    // @ts-ignore
                    value={config[category][del.column].priceRegistered}
                    onChange={(e) => changeConfig(category, del.column, 'priceRegistered', e.target.value)}
                    label="Cena (MM+)"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <TextField
                    variant="standard"
                    // @ts-ignore
                    value={config[category][del.column].freeFromRegistered}
                    onChange={(e) => changeConfig(category, del.column, 'freeFromRegistered', e.target.value)}
                    label="Cena MM+ platí od"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={6}>
                  <TextField
                    variant="standard"
                    // @ts-ignore
                    value={config[category][del.column].image || ''}
                    onChange={(e) => changeConfig(category, del.column, 'image', e.target.value)}
                    label="obrázek v eshopu (url)"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button color="primary" onClick={() => openFileContext(getSaveFile(del.column))}>
                    Nahrát obrázek
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    // @ts-ignore
                    value={config[category][del.column].description || ''}
                    onChange={(e) => changeConfig(category, del.column, 'description', e.target.value)}
                    label="Detailní popis v eshopu"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Grid>
              <br />
              <br />
            </React.Fragment>
          ))
        }
      </CardContent>
    </Card>
  );
}

export default function ShippingConfig() {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState<ShippingPaymentConfiguration | undefined>(undefined);
  const [headerPanel, setHeaderPanel] = useState<{ [key: string]: string }>({});
  const [selectedDomain, setSelectedDomain] = useState(0);
  const layout = useLayout();

  useEffect(
    () => {
      imagesService.get(
        '/text',
        { file: domains[selectedDomain].replace('.', '_') + '_shippingPayment.json', location: 'config' },
        {
          success: setConfig,
          error: () => layout.error('Při načítání dat došlo k chybě'),
        }
      );
    },
    // eslint-disable-next-line
    [selectedDomain]
  );

  useEffect(
    () => {
      webStructureService.get(
        '/header/panel',
        { domain: domains[selectedDomain] },
        {
          success: setHeaderPanel,
          error: () => layout.error('Při načítání dat došlo k chybě'),
        }
      );
    },
    // eslint-disable-next-line
    [selectedDomain]
  );

  const saveChanges = () => {
    setLoading(true);
    imagesService.post(
      'uploadText',
      { file: domains[selectedDomain].replace('.', '_') + '_shippingPayment.json', location: 'config' },
      config,
      {
        success: (c) => {
          layout.success('Změny byly uloženy');
          setLoading(false);
        },
        error: () => layout.error('Při ukládání došlo k chybě'),
      }
    );
  };

  const changeHeaderPanelItem = (key: string, newValue: string) => {
    webStructureService.post('/header/panel', { id: key, domain: domains[selectedDomain] }, newValue, {
      success: () => setHeaderPanel({ ...headerPanel, [key]: newValue }),
      error: () => layout.error('Při ukládání dat došlo k chybě'),
    });
  };

  const changeConfig = (card: string, category: string, field: string, value: string) => {
    const newConfig: { [index: string]: any } = { ...config };
    newConfig[card] = { ...newConfig[card] };
    newConfig[card][category] = { ...newConfig[card][category] };
    newConfig[card][category][field] = isNaN(parseFloat(value)) ? value : parseFloat(value);
    setConfig(newConfig as ShippingPaymentConfiguration);
  };

  const classes = useStyles();

  return (
    (config && (
      <>
        <Tabs color="primary" value={selectedDomain} onChange={(_, val) => setSelectedDomain(val)}>
          {domains.map((d) => (
            <Tab key={d} label={d} />
          ))}
        </Tabs>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PricesConfigCard
                category="shippingPrices"
                changeConfig={changeConfig}
                config={config}
                label="Ceny dopravy"
                layout={layout}
                types={shippingTypes}
              />
            </Grid>
            <Grid item xs={12}>
              <PricesConfigCard
                category="paymentPrices"
                changeConfig={changeConfig}
                config={config}
                label="Ceny plateb"
                layout={layout}
                types={paymentTypes}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Náklady na platbu</Typography>
                  <br />
                  {paymentTypes.map((payment) => (
                    <React.Fragment key={payment.column}>
                      <Typography variant="caption">{payment.label}</Typography>
                      <Grid container spacing={3}>
                        {shippingTypes.map((del) => (
                          <React.Fragment key={del.column}>
                            <Grid item xs={12} md={4}>
                              <TextField
                                variant="standard"
                                // @ts-ignore
                                value={config.paymentCosts[payment.column][del.column]?.relative}
                                onChange={(e) =>
                                  changeConfig('paymentCosts', payment.column, del.column, {
                                    // @ts-ignore
                                    ...config.paymentCosts[payment.column][del.column],
                                    relative: e.target.value,
                                  })
                                }
                                label={`${del.label} (%)`}
                                type="number"
                                fullWidth
                              />
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                      <Grid container spacing={3}>
                        {shippingTypes.map((del) => (
                          <React.Fragment key={del.column}>
                            <Grid item xs={12} md={4}>
                              <TextField
                                variant="standard"
                                // @ts-ignore
                                value={config.paymentCosts[payment.column][del.column]?.absolute}
                                onChange={(e) =>
                                  changeConfig('paymentCosts', payment.column, del.column, {
                                    // @ts-ignore
                                    ...config.paymentCosts[payment.column][del.column],
                                    absolute: e.target.value,
                                  })
                                }
                                label={`${del.label} (přirážka)`}
                                type="number"
                                fullWidth
                              />
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                      <br />
                    </React.Fragment>
                  ))}
                </CardContent>
              </Card>
              <br />
              <Card>
                <CardContent>
                  <Typography variant="h5">Horní panel</Typography>
                  <br />
                  {Object.keys(headerPanel).length &&
                    [
                      { id: 'pickup', label: 'Osobní odběr' },
                      { id: 'home', label: 'Doručení domů' },
                      { id: 'delivery', label: 'Doprava' },
                    ].map(({ id, label }) => (
                      <React.Fragment key={id}>
                        <TextField
                          variant="standard"
                          fullWidth
                          value={headerPanel[id] || ''}
                          onChange={(e) => setHeaderPanel({ ...headerPanel, [id]: e.target.value })}
                          onBlur={(e) => changeHeaderPanelItem(id, e.target.value)}
                          label={label}
                        />
                      </React.Fragment>
                    ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Celkové náklady na balné a přepravu</Typography>
                  <br />
                  {[
                    { label: 'Balné', column: 'packaging' },
                    { label: 'Balné - osobní převzetí', column: 'packagingPickup' },
                    ...shippingTypes,
                  ].map((del) => (
                    <React.Fragment key={del.column}>
                      <Typography variant="caption">{del.label}</Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="standard"
                            // @ts-ignore
                            value={config.shippingCosts[del.column]?.small}
                            onChange={(e) => changeConfig('shippingCosts', del.column, 'small', e.target.value)}
                            label="Malý balík"
                            type="number"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="standard"
                            // @ts-ignore
                            value={config.shippingCosts[del.column]?.big}
                            onChange={(e) => changeConfig('shippingCosts', del.column, 'big', e.target.value)}
                            label="Velký balík"
                            type="number"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <br />
                    </React.Fragment>
                  ))}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="standard"
                        value={config.bigPackageFrom}
                        onChange={(e) =>
                          setConfig({
                            ...config,
                            // @ts-ignore
                            bigPackageFrom: isNaN(parseFloat(e.target.value))
                              ? e.target.value
                              : parseFloat(e.target.value),
                          })
                        }
                        label="Velký balík od (Kč) - balné"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="standard"
                        value={config.bigPackageFromMass}
                        onChange={(e) =>
                          setConfig({
                            ...config,
                            // @ts-ignore
                            bigPackageFromMass: isNaN(parseFloat(e.target.value))
                              ? e.target.value
                              : parseFloat(e.target.value),
                          })
                        }
                        label="Velký balík od (kg) - doprava"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="standard"
                        value={config.fuelExtraCosts}
                        onChange={(e) =>
                          setConfig({
                            ...config,
                            // @ts-ignore
                            fuelExtraCosts: isNaN(parseFloat(e.target.value))
                              ? e.target.value
                              : parseFloat(e.target.value),
                          })
                        }
                        label="Palivový příplatek (%)"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="standard"
                        value={config.seasonAdditionalCosts}
                        onChange={(e) =>
                          setConfig({
                            ...config,
                            // @ts-ignore
                            seasonAdditionalCosts: isNaN(parseFloat(e.target.value))
                              ? e.target.value
                              : parseFloat(e.target.value),
                          })
                        }
                        label="Sezonní příplatek (Kč)"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="standard"
                        value={config.smallPackageMass}
                        onChange={(e) =>
                          setConfig({
                            ...config,
                            // @ts-ignore
                            smallPackageMass: isNaN(parseFloat(e.target.value))
                              ? e.target.value
                              : parseFloat(e.target.value),
                          })
                        }
                        label="Hmotnost malého balíku (g)"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="standard"
                        value={config.bigPackageMass}
                        onChange={(e) =>
                          setConfig({
                            ...config,
                            // @ts-ignore
                            bigPackageMass: isNaN(parseFloat(e.target.value))
                              ? e.target.value
                              : parseFloat(e.target.value),
                          })
                        }
                        label="Hmotnost velkého balíku (g)"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Ostatní nastavení</Typography>
                  <br />
                  <TextField
                    variant="standard"
                    value={config.heurekaMargins || ''}
                    type="number"
                    onChange={(e) => setConfig({ ...config, heurekaMargins: parseFloat(e.target.value) })}
                    label="Minimální marže na Heuréce"
                  />
                  <TextField
                    variant="standard"
                    value={config.zboziMargins || ''}
                    type="number"
                    onChange={(e) => setConfig({ ...config, zboziMargins: parseFloat(e.target.value) })}
                    label="Minimální marže na Zbozi.cz"
                  />

                  <br />
                  <TextField
                    variant="standard"
                    value={config.commonTradeSurcharge || ''}
                    type="number"
                    onChange={(e) => setConfig({ ...config, commonTradeSurcharge: parseFloat(e.target.value) })}
                    label="Běžná obchodní přirážka"
                    fullWidth
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <LoadingFab
            color="primary"
            variant="extended"
            className={classes.saveBtn}
            label="Uložit změny"
            loadingLabel="Ukládání"
            loading={loading}
            onClick={saveChanges}
          />
        </div>
      </>
    )) || <></>
  );
}
