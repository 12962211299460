import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import LoadingButton from './LoadingButton';
import { Settings } from '@mui/icons-material';
import { useLayout } from '../Layout/LayoutContext';
import { imagesService } from '../Utils/ApiService';

export interface IBonusesValuesSettings {
  'TV campaign': number;
  Magazine: number;
  'Member fee': number;
}

export const BonusesValuesSettingsModal = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<IBonusesValuesSettings>({
    'Member fee': 0,
    Magazine: 0,
    'TV campaign': 0,
  });
  const [uploading, setUploading] = useState(false);

  const layout = useLayout();

  useEffect(() => {
    imagesService.get(
      '/text',
      { file: 'bonusesWriteupValues.json', location: 'config' },
      {
        success: setData,
        error: () => layout.error('Při načítání dat došlo k chybě'),
      }
    );
    // eslint-disable-next-line
  }, []);

  const saveData = () => {
    setUploading(true);
    imagesService.post('uploadText', { file: 'bonusesWriteupValues.json', location: 'config' }, data, {
      success: (c) => {
        layout.success('Změny byly uloženy');
        setUploading(false);
        setOpen(false);
      },
      error: () => {
        layout.error('Při ukládání došlo k chybě');
        setUploading(false);
      },
    });
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Settings />
      </IconButton>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={() => setOpen(false)}>
        <DialogTitle>Nastavení hodnot přípisů</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="TV Kampaň"
                type="number"
                value={data['TV campaign']}
                fullWidth
                onChange={(e) => setData({ ...data, 'TV campaign': Number(e.target.value) })}
                InputProps={{
                  endAdornment: <InputAdornment position="end">Kč</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Magazín"
                type="number"
                value={data.Magazine}
                fullWidth
                onChange={(e) => setData({ ...data, Magazine: Number(e.target.value) })}
                InputProps={{
                  endAdornment: <InputAdornment position="end">Kč</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Členský poplatek"
                type="number"
                value={data['Member fee']}
                fullWidth
                onChange={(e) => setData({ ...data, 'Member fee': Number(e.target.value) })}
                InputProps={{
                  endAdornment: <InputAdornment position="end">Kč</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Zavřít
          </Button>
          <LoadingButton loading={uploading} onClick={saveData} label="Uložit" loadingLabel="Ukládání..." />
        </DialogActions>
      </Dialog>
    </>
  );
};
