import React, { useEffect, useState } from 'react';
import { BonusContoRecordState, BonusContoRecordType, IBonusContoProductInfo, IBonusContoRecord } from '../Types/base';
import { catalogService } from '../Utils/ApiService';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { round, separateThousands } from '../Utils/Utils';
import { Cancel, Check, QuestionMark, TrendingDown, TrendingUpSharp } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { useLayout } from '../Layout/LayoutContext';
import { BonusesValuesSettingsModal } from '../Components/BonusesValuesSettingsModal';

function TypeBadge({ type }: { type: BonusContoRecordType }) {
  return (
    <Chip
      label={type === 'Write-off' ? 'Odpis' : 'Přípis'}
      color={type === 'Write-off' ? 'error' : 'success'}
      icon={type === 'Write-off' ? <TrendingDown /> : <TrendingUpSharp />}
    />
  );
}

function StatusBadge({ status }: { status: BonusContoRecordState }) {
  return (
    <Chip
      label={status === 'correct' ? 'Správně' : status === 'incorrect' ? 'Nesprávně' : 'Nekontrolováno'}
      color={status === 'correct' ? 'success' : status === 'incorrect' ? 'error' : 'default'}
      icon={status === 'correct' ? <Check /> : status === 'incorrect' ? <Cancel /> : <QuestionMark />}
    />
  );
}

function BonusInfoModal({
  record,
  onClose,
  onUpdate,
}: {
  record?: IBonusContoRecord;
  onClose: () => void;
  onUpdate: (updated: IBonusContoRecord) => void;
}) {
  const [items, setItems] = useState<IBonusContoProductInfo[]>([]);

  const layout = useLayout();

  useEffect(() => {
    if (record)
      catalogService.get(
        '/bonusConto/bonusDetails',
        { id: record?.id },
        { success: setItems, error: () => layout.error('Při načítání produktů došlo k chybě') }
      );
    else setItems([]);
    // eslint-disable-next-line
  }, [record]);

  const colTitle =
    record?.subtype === 'Bonification'
      ? 'Bonifikace'
      : record?.subtype === 'Sell-out'
      ? 'Sell-out'
      : 'Rozšířená spolupráce';

  const modifiedItems = (items || [])
    .map((i) => ({
      ...i,
      valueOfInterest:
        record?.subtype === 'Bonification'
          ? i.bonification
          : record?.subtype === 'Sell-out'
          ? i.sellOut
          : record?.subtype === 'Extra bonus'
          ? i.invoiceBonus
          : 0,
    }))
    .filter((i) => i.valueOfInterest > 0);

  return (
    <Dialog maxWidth="lg" fullWidth open={record !== undefined} onClose={onClose}>
      <DialogContent>
        {modifiedItems.length > 0 ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Produkt</TableCell>
                <TableCell>Prodáno ks</TableCell>
                <TableCell>{colTitle} na ks</TableCell>
                <TableCell>{colTitle} celkem</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modifiedItems.map((item) => (
                <TableRow key={item.productId}>
                  <TableCell>
                    {item.productTitle} {item.productSubtitle}
                  </TableCell>
                  <TableCell>{separateThousands(item.soldItems)}</TableCell>
                  <TableCell>{separateThousands(round(item.valueOfInterest, 2))} Kč</TableCell>
                  <TableCell>{separateThousands(round(item.valueOfInterest * item.soldItems, 2))} Kč</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          'K tomuto záznamu se nevztahují žádné produkty.'
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Zavřít</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function BonusesControl() {
  const [data, setData] = useState<IBonusContoRecord[]>([]);
  const [month, setMonth] = useState(new Date());
  const [openRow, setOpenRow] = useState<IBonusContoRecord | undefined>(undefined);

  const reloadData = (date: Date) => {
    setData([]);
    catalogService.get(
      '/bonusConto/list',
      {
        from: new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0],
        to: new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().split('T')[0],
      },
      {
        success: (data: IBonusContoRecord[]) =>
          setData(data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())),
        error: console.log,
      }
    );
  };

  // eslint-disable-next-line
  useEffect(() => reloadData(month), []);

  return (
    <>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <DatePicker
            value={month}
            onChange={(date) => {
              if (date) {
                if (date.getMonth() !== month.getMonth() || date.getFullYear() !== month.getFullYear())
                  reloadData(date);
                setMonth(date);
              }
            }}
            views={['year', 'month']}
            label="Měsíc"
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <BonusesValuesSettingsModal />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell>Název</TableCell>
                <TableCell>Procenta</TableCell>
                <TableCell>Hodnota</TableCell>
                <TableCell>Předpokládaná hodnota</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d) => (
                <TableRow style={{ cursor: 'pointer' }} hover onClick={() => setOpenRow(d)} key={d.id}>
                  <TableCell>{new Date(d.date).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <TypeBadge type={d.type} />
                  </TableCell>
                  <TableCell>{d.title}</TableCell>
                  <TableCell>{100 * d.percentage} %</TableCell>
                  <TableCell>{separateThousands(d.price)} Kč</TableCell>
                  <TableCell>
                    {d.expectedValue > 0 ? separateThousands(round(d.expectedValue, 2)) + ' Kč' : ''}
                  </TableCell>
                  <TableCell>
                    <StatusBadge status={d.state} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <BonusInfoModal
        record={openRow}
        onClose={() => setOpenRow(undefined)}
        onUpdate={(updated) => setData(data.map((d) => (d.id === updated.id ? updated : d)))}
      />
    </>
  );
}
